import axios from "axios";
// import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
var CryptoJS = require("crypto-js");


// ---------------------------------------------------------------------------------------------------------
// All data which would be loaded before the application starts
// ---------------------------------------------------------------------------------------------------------

export const preDataLoad = () => {
    return (dispatch) => {
      dispatch(fetchPreDataStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));

      if(cipherToken){
          // //console.log(cipherToken);
      
          var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
          var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }else{
          decryptedToken=''
        }
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/preDataLoad`, axiosConfig)
        .then((response) => {
          if (response.data) {
            // console.log(response);
  
            dispatch(fetchPreDataSuccess(response.data));
  
        
          } 
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            localStorage.removeItem("fkey");
            localStorage.removeItem("AuthUser");
            History.replace("/");
          }

          dispatch(fetchPreDataFailure(err.message));
        });
    };
  };
  
  const fetchPreDataStarted = () => ({
    type: "preData_started",
  });
  
  const fetchPreDataSuccess = (data) => ({
    type: "preData_success",
    payload: {
      ...data,
    },
  });
  
  const fetchPreDataFailure = (error) => ({
    type: "preData_failure",
    payload: {
      error,
    },
  });
  




  