import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import {useParams} from 'react-router-dom';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import ImagesCarousel from '../../InventorySystem/Vehicles/ViewVehicleCarousel';
import moment from "moment";
import {downloadImagesZipInventory, getVehicleDetailsInventory} from '../../../../redux/actions';
import { useTranslation } from 'react-i18next'
import { can } from "../../../utils/roleUtils";
function VehicleView(props) {
  const [id, setId] = useState('');
  const params = useParams();
  const {t}=useTranslation();


useEffect(()=>{
 let _ciphertext= CryptoAES.decrypt(params.id, 'autoListGhulam_123');
 let decryptValue=_ciphertext.toString(CryptoENC);
if(decryptValue){
  setId(decryptValue);
props.getVehicleDetailsInventory(decryptValue);
}
},[])

// const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;

const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


  return (
    <div>
<div className="container-fluid" style={{paddingLeft:'0px',overflowX:'hidden'}}>


<div className="row pt-2">
<div className="col">
<div className="p-2 card-custom-hover-auto-view">
<h4>{props.getVehicleDetails?.data?.year} {props.getVehicleDetails?.data?.make} {props.getVehicleDetails?.data?.model}  </h4>
<div className="row">
<div className="col-md-3"><span className="info-title">LOT: </span> <span className="info">{props.getVehicleDetails?.data?.lot}</span></div>
<div className="col-md-3"><span className="info-title">VIN: </span> <span className="info">{props.getVehicleDetails?.data?.vin}</span></div>
</div>
</div>
</div>

</div>

<div className="pt-3">

<div className='row' >
<div className="col-md-8">

<>

{props.getVehicleDetails?.images.length>0?
<>
<div className="downloadImages"><a onClick={()=>props.downloadImagesZipInventory(id)}><i class="fas fa-download"></i> Download Images</a></div>
</>
:''}
</>


{props.getVehicleDetails?.images?.length>0?

<>

<ImagesCarousel autoImages={props.getVehicleDetails?.images}/>
{/* <div>
<ImageCustomCarousel
          zoomEffect={true}
          customThumnailColor={'#fcac32'}
            images={props.getVehicleDetails?.images.map((img) => `${process.env.REACT_APP_IMAGES_ADRESS}/${img.image_name}`)} // Pass the image URLs as the `images` prop
          />
          </div> */}
</>
:      <img src={require('./../../../assets/noimage.png')} alt="No images found" className="no-images-found" />

}
</div>
<div className="col-md-4">
<div className="card card-custom-hover-auto-view ">

<div className="container langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
  <div className="row">
    <div className="col-12">
       <span className="header-title">{t('key_auto_details')} </span> 
    </div>
    <hr/>
  </div>


  <div className="row text-size-info">
    <div className="col-md-6 pb-1">
      <span className="info-title">Vin:</span>
       <span className="info"> {props.getVehicleDetails?.data?.vin} </span>
    </div>
  
    <div className="col-md-6 pb-1">
      <span className="info-title">Lot:</span>
      <span className="info"> {props.getVehicleDetails?.data?.lot} </span>
    </div>


    <div className="col-md-6 pb-1">
      <span className="info-title">{t('key_auction')}:</span> 
      <span className="info"> {props.getVehicleDetails?.data?.auction} </span>
    </div>
    <div className="col-md-6 pb-1">
      <span className="info-title">{t('key_buyer_no')}:</span>
      <span className="info"> {props.getVehicleDetails?.data?.buyer_no} </span>
    </div>
    <div className="col-md-6 pb-1">
      <span className="info-title">{t('key_color')}:</span>
       <span className="info"> {props.getVehicleDetails?.data?.color} </span>
    </div>
    <div className="col-md-6 pb-1">
      <span className="info-title">{t('key_keys')}:</span> 
      <span className="info"> {props.getVehicleDetails?.data?.keys === 'true' ? 'Yes' : 'No'} </span>
    </div>
    <div className="col-md-6 pb-1">
      <span className="info-title">{t('key_point_of_loading')}:</span>
      <span className="info"> {props.getVehicleDetails?.data?.point_loading} </span>
    </div>
    <div className="col-md-6 pb-1">
      <span className="info-title">{t('key_city')}:</span>
      <span className="info"> {props.getVehicleDetails?.data?.city} </span>
    </div>
  

    <div className="col-md-12 pb-1">
      <span className="info-title">{t('key_destination_port')}:</span>
      <span className="info"> {props.getVehicleDetails?.data?.port} </span>
    </div>
  

    <div className="col-md-6 pb-1">
      <span className="info-title">{t('key_container_no')}:</span>
       <span className="info">{props.getVehicleDetails?.data?.container_num_booking} </span>
    </div>
  </div>
</div>


</div>

<br/>
{/* 
<div className="card side-card border-0">

<Table responsive="md"  striped={true}  className="langDirection"  dir={JSON.parse(localStorage.getItem('lang_dir'))}>
    <thead>
      <tr>
        
        <th colSpan="2">{t('key_more_info')} <span><i className="fas fa-truck-moving"></i></span> </th>
      
     
      </tr>
    </thead>
    <tbody >

 
 
   
    
    <tr >
    <td>{t('key_auto_type')}:</td>
    <td>{props.getVehicleDetails?.data?.auto_type}</td>
    </tr>
    <tr>
    <td>{t('key_date')}:</td>
    <td>{moment(props.getVehicleDetails?.data?.created_at).format('YYYY-MM-DD')}</td>
    </tr>

    <tr>
    <td>{t('key_purchase_date')}:</td>
    <td>{moment(props.getVehicleDetails?.data?.purchase_date).format('YYYY-MM-DD')}</td>
    </tr>

    <tr>
    <td>{t('key_promised_pick_up')}:</td>
    <td>{props.getVehicleDetails?.data?.promise_pickup}</td>
    </tr>

    <tr>
    <td>{t('key_delievered_to_warehouse')}:</td>
    <td>{props.getVehicleDetails?.data?.delivered_warehouse}</td>
    </tr>

    <tr>
    <td>{t('key_arrival_date')}:</td>
    <td>{props.getVehicleDetails?.data?.arrival_date}</td>
    </tr>

    <tr>
    <td>{t('key_unloading_date')}:</td>
    <td>{props.getVehicleDetails?.data?.unloading_date}</td>
    </tr>
     
    </tbody>
  </Table>


</div> */}


<div className="card card-custom-hover-auto-view">
  <div className="container langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
    <div className="row">
      <div className="col-12">
        <span className="header-title">{t('key_more_info')}</span>
        
      </div>
      <hr/>
    </div>
    <div className="row text-size-info">
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_auto_type')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.auto_type}</span>
      </div>
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_date')}:</span>
        <span className="info"> {moment(props.getVehicleDetails?.data?.created_at).format('YYYY-MM-DD')}</span>
      </div>
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_purchase_date')}:</span>
        <span className="info"> {moment(props.getVehicleDetails?.data?.purchase_date).format('YYYY-MM-DD')}</span>
      </div>
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_promised_pick_up')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.promise_pickup}</span>
      </div>
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_delievered_to_warehouse')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.delivered_warehouse}</span>
      </div>
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_arrival_date')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.arrival_date}</span>
      </div>
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_unloading_date')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.unloading_date}</span>
      </div>
    </div>
  </div>
</div>

<br/>
<div className="card card-custom-hover-auto-view">
  <div className="container langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
    <div className="row">
      <div className="col-12">
        <span className="header-title">Other Information </span>
      </div>
      <hr/>
    </div>
    <div className="row text-size-info">
      <div className="col-md-12 pb-1">
        <span className="info-title">{t('key_title')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.title_status === "true" ? 'Yes' : 'No'}</span>
      </div>
      <div className="col-md-12 pb-1">
        <span className="info-title">{t('key_notes')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.customer_notes}</span>
      </div>
      <div className="col-md-12 pb-1">
        <span className="info-title">{t('key_terminal_notes')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.terminal_notes}</span>
      </div>
    </div>
  </div>
</div>

</div>


{/* 
<div className="col-md-12">
<br/>
<div className="card card-custom-hover-auto-view">
  <div className="container langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))}>
    <div className="row">
      <div className="col-12">
        <span className="header-title">Other Information </span>
      </div>
    </div>
    <div className="row text-size-info">
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_title')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.title_status === "true" ? 'Yes' : 'No'}</span>
      </div>
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_notes')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.customer_notes}</span>
      </div>
      <div className="col-md-6 pb-1">
        <span className="info-title">{t('key_terminal_notes')}:</span>
        <span className="info"> {props.getVehicleDetails?.data?.terminal_notes}</span>
      </div>
    </div>
  </div>
</div>


</div> */}






</div>

</div>
</div>


        
    </div>
  )
}



const mapStateToProps = (state) => {

    const {loading, getVehicleDetails } = state.InventoryHandler;
  
    const { preDataLoadResponse } = state.preDataHandler;

    return {
    
      loading:loading,
      preDataLoadResponse:preDataLoadResponse,
     
      getVehicleDetails:getVehicleDetails,

     
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
  
      getVehicleDetailsInventory:(id)=>dispatch(getVehicleDetailsInventory(id)),
      downloadImagesZipInventory:(id)=>dispatch(downloadImagesZipInventory(id)),

    };
  };



export default connect(mapStateToProps,mapDispatchToProps)(VehicleView)