import "../../../../css/InventoryCss/VehicleList.css";
import "../../../../css/searchBox.css";
import "../../../../css/fixbar.css";
import "../../../../css/rightBar.css";
import {can,hasRole,hasAnyRole,isGuest} from "../../../utils/roleUtils";
import {searchText} from '../../../utils/searchUtils';
import "../../../../css/circleAroundNumber.css"
import "react-datepicker/dist/react-datepicker.css";
import "../../../../css/badges.css"
import NodataFound from '../../../pages/error/noDataFoundMain';
import Logo from "../../../assets/normLogo.png";
import BillView from '../../../pages/autos/BillView';
import { AsyncPaginate } from "react-select-async-paginate";
import NavBar from "../Elements/NavBarInventory";
import React, { useState, useEffect,useRef } from "react";
import { useNavigate,Link,NavLink,useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ProgressBar from "react-bootstrap/ProgressBar";
import Checkbox from '../../../elements/Checkbox';
import { useTranslation } from 'react-i18next'

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Swal from "sweetalert2";
import Loader from "../../../elements/Loader";
import CloseButton from "react-bootstrap/esm/CloseButton";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";
import _ from "lodash";
// import RightSidebar from '../../../elements/RightSidebar';
import axios from "axios";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import ImagesCarousel from '../../InventorySystem/Vehicles/VehiclesCarousel';
import ErrorNo from '../../error/NoDataError';
import AutosCommunication from '../../autos/AutosCommunication'
import MessageBox from "../../../elements/MessageBox";
import {loadUsersOptions} from '../../../utils/asyncOptionsUtils';
import "../Css/VehicleCard.css";
import QrCodeGenerator from "../Elements/QrCodeGenerator";

import {
  getSelectors,
  storeInventoryData,
  showInventoryRegModal,
  hideInventoryRegModal,
  InventoryList,
  showInventoryAdditionalModal,
  hideInventoryAdditionalModal,
  InventoryAdditionalRegistration,
  resetProgressBar,
  getImagesInventory,
  getImages,
  changePointOfLoadingInventory,
  filterListForWarhouses,
  inputHandler,
  searchInventoryList,
  InventoryDelete,
  selectedInventoryDelete,
  usersSelectorListInventory,
  storeImagesInventory,
  getInventoryCommunication,
  getLatestNotificationDetails,
  NotificationRead,
  authCheck,
  getVehicleDetailsInventory

} from "../../../../redux/actions";

import QRCode from 'react-qr-code';


import { billCatagory } from "../../../utils/permissionsUtils";
// import { Document,Page } from 'react-pdf/dist/esm/entry.webpack';
import ImagesGrid from '../../InventorySystem/Vehicles/VehicleImagesEditModal';
import CryptoAES from 'crypto-js/aes';
import Cookies from "js-cookie";
import WhatsAppButton from "../Elements/WhatsAppButton";

function VehicleList(props) {
  // const [show, setShow] = useState('');
  const [autosId, setAutosID] = useState('');
  const [value, onChange] = useState(null);
  const [editSelector, setEditSelector] = useState(null);

  const [closeMessageBox, setCloseMessageBox] = useState(false);

  const [Loading, setLoading] = useState(false);
  const [headerSearch, setHeaderSearch] = useState('autoListSearchHeaderSticky');
  const [searched, setSearched] = useState(false);

  const [dragAndDropText, setdragAndDropText] = useState(
    "Upload or Drag and Drop your file here"
  );
  //for additional images upload here

  const [dragAndDropTextImages, setdragAndDropTextImages] = useState(
    "Upload or Drag and Drop images here"
  );
  const [dragAndDropSelected, setdragAndDropSelected] = useState(false);

  //for additional invoice upload
  const [dragAndDropAdditionalInvoiceText, setdragAndDropAdditionalInvoiceText] = useState(
    "Upload or Drag and Drop your file here"
  );
  const [dragAndDropAdditionalInvoiceSelected, setdragAndDropAdditionalInvoiceSelected] = useState(false);


 

  const [dragAndDropSelectedImages, setdragAndDropSelectedImages] = useState(false);
  const [imagesModal, setImagesModal] = useState(false);


  const [autoDetails, setAutoDetails] = useState([
    { model: "", make: "", year: "" },
  ]);

  const [autoRegistrationData, setAutoRegistrationData] = useState(
    { vin:'', lot:'', autoType:null, images:[], model:'', make:'', year:'',color:'',purchase_date:null,auction:null,city:null,buyer_no:null, payment_to_auction:null,port:null,point_of_loading:null, customer_notes:'', dismantle:'false', self_delivered:'false', invoice:null,auto_price:'' },
  );
  
  const [autoRegistrationAdditionalData, setAutoRegistrationAdditionalData] = useState(
    {onId:'', auto_catagory:'', images:[],containerNoBooking:'', pickUp:null, toWarehouse:null, arrival_date:null, unloading_date:null, title_status:'', title_received:null, keys:'',terminal_notes:'', vin:'', lot:'', autoType:null, model:'', make:'', year:'',color:'',purchase_date:null,auction:null,city:null,buyer_no:null, payment_to_auction:null,port:null,point_of_loading:null, customer_notes:'', dismantle:'false', self_delivered:'false', invoice:[], auto_price:'' },
  );
  
  const [autoAdditionalDataError, setAutoAdditionalDataErrors] = useState(
    {auto_catagory_error:''},
  );

  const [billInfo, setBillInfo] = useState(
    { autos_id:'', invoice_catagory:'', amount:'', notes:'',bill_to:'' },
  );

  const [isCheck, setIsCheck] = useState([]);


  // const billCatagory = [
  //   { value: 'Credit', label: 'Credit' },
  //   { value: 'Debit', label: 'Debit' },
   
  // ]



  const navigate = new useNavigate();

  useEffect(()=>{
    props.getSelectors();
    props.authCheck();
    setAutoRegistrationData({...autoRegistrationData,autoType:{ value: "Auto", label: "Auto" }})

  },[]);




  const params = useParams();
  const {t}=useTranslation();

useEffect(()=>{
  
 let ware= params.warehouse?.replace(/\d+/,'');
 let placement= params.placement?.replaceAll('-',' ');
//  console.log(placement);
//  console.log(ware);
if(props.selectedInventoryDeleted){
setIsCheck([]);
}

 if(props.InventoryRegistrationSuccess||props.InventoryAdditionalRegistrationSuccess||props.pointOfLoadingChangeSuccess||props.InventoryDeleted||props.selectedInventoryDeleted || props.InventoryImagesUploadResponse || props.imagesDeletionSuccess){
 
  setAutoRegistrationData( 
    { vin:'', lot:'', autoType:null, images:[], model:'', make:'', year:'',color:'',purchase_date:null,auction:null,city:null,buyer_no:null, payment_to_auction:null,port:null,point_of_loading:null, customer_notes:'', dismantle:'false', self_delivered:'false', invoice:null,auto_price:'' },
    )
 
  props.InventoryList(props?.currentPage);

 }

if(placement && ware){

  props.filterListOfWarhouses(ware,placement,props.currentPage);


}else{
props.InventoryList(props?.currentPage);
}

if(props.notificationsDeletedSuccess){
  props.getLatestNotificationDetails()
}


},[props.InventoryRegistrationSuccess,props.InventoryAdditionalRegistrationSuccess,props.pointOfLoadingChangeSuccess,props.InventoryDeleted,props.selectedInventoryDeleted,props.InventoryImagesUploadResponse,props.imagesDeletionSuccess,props.notificationsDeletedSuccess])

useEffect(()=>{
  if(props.uploadPercentage){
  setTimeout(()=>{
props.resetProgressBar();
  },2000)
}
},[props.uploadPercentage])


useEffect(()=>{
  if(props?.cleanValues){

    setAutoRegistrationData(  {...autoRegistrationData, images:[], vin:'', lot:'', model:'', make:'', year:'',color:'',purchase_date:null,auction:null,city:null,buyer_no:null, payment_to_auction:null,port:null,point_of_loading:null, customer_notes:'', dismantle:'false', self_delivered:'false', invoice:null },
    )
    setdragAndDropSelected(false);
    setdragAndDropText("Upload or Drag and Drop your Invoice here")
    }

    if(props.cleanAdditionalValues){
      setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,images:[],invoice:[]})
      setdragAndDropSelectedImages(false)
      setdragAndDropTextImages("Upload or Drag and Drop your images here")
      setdragAndDropAdditionalInvoiceText("Upload or Drag and Drop your file here")
 
    }
    
},[props?.cleanValues,props.cleanAdditionalValues,props.InventoryImagesUploadResponse])

useEffect(()=>{
  let editData=props.getVehicleDetails?.data;   
  setEditSelector( 
{value:editData?.userId, label:editData?.customerName}
    )
  setAutoRegistrationAdditionalData({
    ...autoRegistrationAdditionalData,
    auto_catagory:editData?.auto_catagory?{value:editData.auto_catagory, label:editData?.auto_catagory}:null,
    title_status:editData?.title_status?{value:editData.title_status, label:editData?.title_status}:'',
    containerNoBooking:editData?.container_num_booking||'',
    pickUp:editData?.promise_pickup? moment(editData?.promise_pickup).toDate():null,
    toWarehouse:editData?.delivered_warehouse? moment(editData?.delivered_warehouse).toDate():null,
    title_received:editData?.title_received? moment(editData?.title_received).toDate():null,
    arrival_date:editData?.arrival_date? moment(editData?.arrival_date).toDate():null,
    unloading_date:editData?.unloading_date? moment(editData?.unloading_date).toDate():null,
    keys:editData?.keys||'',
 
    terminal_notes:editData?.terminal_notes||'',

    // toWarehouse:props.getVehicleDetails?.data?.promise_pickup,
// ==========================================
//  non admin edit details
// ==========================================

vin:editData?.vin||'',
model:editData?.model||'',
make:editData?.make||'',
year:editData?.year||'',
color:editData?.color||'',
lot:editData?.lot||'',
dismantle:editData?.to_dismantle||'',
self_delivered:editData?.self_delivered||'',


customer_notes:editData?.customer_notes||'',

purchase_date:editData?.purchase_date? moment(editData?.purchase_date).toDate():null,
payment_to_auction:editData?.payment_auction? moment(editData?.payment_auction).toDate():null,
auction:editData?.auction?{value:editData.auction, label:editData?.auction}:'',
city:editData?.city?{value:editData.city, label:editData?.city}:'',
buyer_no:editData?.buyer_no?{value:editData.buyer_no, label:editData?.buyer_no}:'',
port:editData?.port?{value:editData.port, label:editData?.port}:'',
point_of_loading:editData?.point_loading?{value:editData.point_loading, label:editData?.point_loading}:'',
autoType:editData?.auto_type?{value:editData.auto_type, label:editData?.auto_type}:'',
auto_price:editData?.auto_price||'',


  
  })

},[props.getVehicleDetails])




// const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
const hasRole=(role)=>(props.preDataLoadResponse?.currentUserRole||[]).find((p)=>p==role)?true:false;
// const hasAnyRole = (...roles) => {
//   const userRoles = props.preDataLoadResponse?.currentUserRole || [];
//   return roles.find(role => userRoles.includes(role)) !== undefined;
// };
const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;

const guestCheck = props.guestCheck;

const listenScrollEvent = () => {
  if (window.scrollY > 99) {
    return setHeaderSearch("autoListSearchHeaderFixed");
  } else if (window.scrollY < 80) {
    return setHeaderSearch("autoListSearchHeaderSticky");
  }
};

useEffect(() => {
  window.removeEventListener("scroll", listenScrollEvent);
  window.addEventListener("scroll", listenScrollEvent);
  return () => window.removeEventListener("scroll", listenScrollEvent);
}, []);



const onSearchInputChange=(e)=>{

  if(e.target.value==='' && searched ){
    
    props.InventoryList(props?.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}

const onSearch=(e)=>{
e.preventDefault();

  if(props.searchInventory){
setSearched(true);
  props.searchInventoryList(props.searchInventory,props.currentPage)
  }else{
    setSearched(false);
   

  }

}

 // ==========================************================
  // All selectors options
  // ==========================************================
  let autoCatagoryOptions; 
if(props?.SelectorsFetch?.auction){
  autoCatagoryOptions = props.SelectorsFetch?.autoCatagory.map(function (data) {
    return { value: data.catagory_name, label: data.catagory_name }
  })
}

let auctionOptions; 
if(props?.SelectorsFetch?.auction){
  auctionOptions = props.SelectorsFetch?.auction.map(function (data) {
    return { value: data.auction_name, label: data.auction_name }
  })
}


let cityOptions; 
if(props?.SelectorsFetch?.city){
  cityOptions = props.SelectorsFetch?.city.map(function (data) {
    return { value: data.city_name, label: data.city_name }
  })
}

let landingPointOptions; 
if(props?.SelectorsFetch?.warehouse){
  landingPointOptions = props.SelectorsFetch?.warehouse.map(function (data) {
    return { value: data.warehouse_name, label: data.warehouse_name }
  })
}

let portOptions; 
if(props?.SelectorsFetch?.port){
  portOptions = props.SelectorsFetch?.port.map(function (data) {
    return { value: data.port_name, label: data.port_name }
  })
}

let buyerOptions; 
if(props?.SelectorsFetch?.buyerNumber){
  buyerOptions = props.SelectorsFetch?.buyerNumber.map(function (data) {
    return { value: data.buyer_no, label: data.buyer_no }
  })
}


let billersOptions; 
if(props?.SelectorsFetch?.billers){
  billersOptions = props.SelectorsFetch?.billers.map(function (data) {
    return { value: data.id, label: data.biller_name }
  })
}

let autoTypeOptions; 
if(props?.SelectorsFetch?.autoType){
  autoTypeOptions = props.SelectorsFetch?.autoType.map(function (data) {
    return { value: data.type_name, label: data.type_name }
  })
}








const handleChangeSelectors = (e,selectorName) => {
  // console.log(autoRegistrationData)
let selectName = selectorName.name.toString();
  setAutoRegistrationData({ ...autoRegistrationData, [selectName]:e });
};

const handleMoreInfoSelectors = (e,selectorName) => {
  setAutoAdditionalDataErrors({auto_catagory_error:''})
  // console.log(autoRegistrationData)
let selectName = selectorName.name.toString();
setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [selectName]:e });

};

const handleBillSelector = (e,selectorName) => {
  // console.log(autoRegistrationData)
let selectName = selectorName.name.toString();
setBillInfo({ ...billInfo, [selectName]:e });

};


const handleCheckbox =(e)=>{
if(e.target.checked){
  setAutoRegistrationData({ ...autoRegistrationData, [e.target.name]:"true" });

}else{
  setAutoRegistrationData({ ...autoRegistrationData, [e.target.name]:"false" });

}

}



const handleCheckboxAddtional =(e)=>{
  if(e.target.checked){
    setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [e.target.name]:"true" });
  
  }else{
    setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [e.target.name]:"false" });
  
  }
  
  }




const onInputChange=(e)=>{

  setAutoRegistrationData({ ...autoRegistrationData, [e.target.name]:e.target.value });

}





const onInputChangeBill=(e)=>{

setBillInfo({ ...billInfo, [e.target.name]:e.target.value });

}


const onInputChangeAdditional=(e)=>{

  setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [e.target.name]:e.target.value });

}

const handleDateInputChange=(e,dateSelectorName)=>{
// let formated = moment(e).format('YYYY-MM-DD')
  setAutoRegistrationData({ ...autoRegistrationData, [dateSelectorName]:e});

}


const handleDateInputChangeAdditional=(e,dateSelectorName)=>{
  // let formated = moment(e).format('YYYY-MM-DD')
    setAutoRegistrationAdditionalData({ ...autoRegistrationAdditionalData, [dateSelectorName]:e});
  
  }


const handleChange = (file) => {
  
  if (_.isEmpty(file)) {
    setdragAndDropSelected(true);
    setdragAndDropText(file.name);
    setAutoRegistrationData({...autoRegistrationData, 'invoice':file})
  } else {
    setdragAndDropSelected(false);
  }
};
const handleChangeAdditional = (files) => {
  if (files && files.length > 0) {
    // Convert FileList to an array
    const fileArray = Array.from(files); // OR: const fileArray = [...files];

    // Extract file names
    const fileNames = fileArray.map(file => file.name);

    setdragAndDropAdditionalInvoiceSelected(true);
    setdragAndDropAdditionalInvoiceText(fileNames.join(", ")); // Display file names as a comma-separated string
    setAutoRegistrationAdditionalData(prevState => ({
      ...prevState,
      invoice: [...prevState.invoice, ...fileArray], // Add new files to the existing array
    }));
  } else {
    setdragAndDropAdditionalInvoiceSelected(false);
    setdragAndDropAdditionalInvoiceText("No file selected");
  }
};





const handleChangeImages = (FileList) => {
  // console.log(FileList)
  if (FileList.length>0) {
    setdragAndDropSelectedImages(true);
    if(FileList.length==1){
    setdragAndDropTextImages(`${FileList.length} image`);
    }else{
      setdragAndDropTextImages(`${FileList.length} images`);
    }
    const imagesArray = [];

    for (let i = 0; i < FileList.length; i++) {      
      imagesArray.push(FileList[i]);
    }

    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData, 'images':imagesArray})
  } else {
    setdragAndDropSelectedImages(false);

  }
};


const handleImagesAddVehicle = (FileList) => {
  // console.log(FileList)
  if (FileList.length>0) {
    setdragAndDropSelectedImages(true);
    if(FileList.length==1){
    setdragAndDropTextImages(`${FileList.length} image`);
    }else{
      setdragAndDropTextImages(`${FileList.length} images`);
    }
    const imagesArray = [];

    for (let i = 0; i < FileList.length; i++) {      
      imagesArray.push(FileList[i]);
    }

    setAutoRegistrationData({...autoRegistrationData, 'images':imagesArray})
  } else {
    setdragAndDropSelectedImages(false);

  }
};

const handlePageChange=(pageNumber) => {
  // console.log(`active page is ${pageNumber}`);
  

let warehouse = params.warehouse?.replace(/([A-Za-z]+\d+)|\s+/g, (match, p1) => {
  if (p1) {
    return p1; // If it's a prefix followed by digits, return it as is
  }
  return ''; // Otherwise, replace spaces with empty
});

  props.filterListOfWarhouses(warehouse,params.placement?.replaceAll('-',' '),pageNumber)

}

const handleSearchPages=(pageNumber)=>{
  props.searchInventoryList(props.searchInventory,pageNumber)

}

const onStoreAdditionalData=(e)=>{
e.preventDefault();



  props.InventoryAdditionalRegistration(autoRegistrationAdditionalData,props.InventoryListResponse?.data?.current_page,editSelector);



}

const submitChangePointOfLoading=(e)=>{

  e.preventDefault();
  if(autoRegistrationAdditionalData.point_of_loading==null || autoRegistrationAdditionalData.auto_catagory==null){
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Please fill both selection fields !",
      timer: 2000,
      timerProgressBar: true,
      toast: true,
      confirmButtonColor: "#0a4b85",
    });
}else{
  props.changePointOfLoadingInventory(autoRegistrationAdditionalData,props.InventoryListResponse?.data?.current_page);

}

}



const submitAutoInvoice=(e)=>{
e.preventDefault();
if(billInfo.invoice_catagory==""){
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Please select the type of invoice !",
    timer: 2000,
    timerProgressBar: true,
    toast: true,
    confirmButtonColor: "#0a4b85",
  });
}else{
//   props.registerInventoryInvoice(billInfo)

}


}




  const viewAutoInfo = (id) => {
    navigate(`/home/autoView/${encodeURIComponent(CryptoAES.encrypt(id.toString(),'autoListGhulam_123'))}`);
  };

  //functions for model pop up and close
  const handleEditClose = () => props.hideInventoryEditModal();
  const handleAdditionalClose = () => {
    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,invoice:[]})
    setdragAndDropAdditionalInvoiceSelected(false)
    setdragAndDropAdditionalInvoiceText("Upload or Drag and Drop your images here")
    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,images:[]})

    setdragAndDropSelectedImages(false)
    setdragAndDropTextImages("Upload or Drag and Drop your images here")
    props.hideInventoryAdditionalModal()

    setAutoAdditionalDataErrors({auto_catagory_error:''})

  };

  const handleClose = () => props.hideInventoryRegModal();
  const handleShow = () => props.showInventoryRegModal();
  

  // ==========================************================
  // Function for VIN Decoding
  // ==========================************================

  const vinValidation = vin => {
    if(vin){
    const regex = new RegExp('[A-HJ-NPR-Z0-9]{17}');    
    return regex.test(vin);
    }
  };



  const vinDecode = async (vin) => {
setLoading(false);
    setAutoRegistrationData({...autoRegistrationData, vin:vin});
    // console.log(vin);
    if (vinValidation(vin)) {
      setLoading(true);
      axios.get(`${process.env.REACT_APP_API_ADDRESS}/vinDecoder/${vin}`)
      .then(function (response) {
        // console.log(response);
        setAutoRegistrationData({
            ...autoRegistrationData,
            vin:vin,
            model:response.data.model?.name,
            make:response.data.make?.name,
            year:response.data?.years[0]?.year
            });

            setLoading(false);

      })
      .catch(function (error) {
        setLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There is some problem in the network!, please try manual process",
          timer: 2000,
          timerProgressBar: true,
          toast: true,
          confirmButtonColor: "#0a4b85",
        });
      });

      
   

    } else {
      if (vin.length >= 17) {
        setLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your VIN is wrong!",
          timer: 2000,
          timerProgressBar: true,
          toast: true,
          confirmButtonColor: "#0a4b85",
        });
      }
    }
  };





  const vinDecodeAdditional = async (vin) => {
    setLoading(false);

    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData, vin:vin});
    // console.log(vin);
    if (vinValidation(vin)) {
      setLoading(true);
      axios.get(`${process.env.REACT_APP_API_ADDRESS}/vinDecoder/${vin}`)
      .then(function (response) {
        // console.log(response);
        setAutoRegistrationAdditionalData({
            ...autoRegistrationAdditionalData,
            vin:vin,
            model:response.data.model?.name,
            make:response.data.make?.name,
            year:response.data?.years[0]?.year
            });

            setLoading(false);

      })
      .catch(function (error) {
        setLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There is some problem in the network!, please try manual process",
          timer: 2000,
          timerProgressBar: true,
          toast: true,
          confirmButtonColor: "#0a4b85",
        });

      });

      
   

    } else {
      if (vin.length >= 17) {
        setLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Your VIN is wrong!",
          timer: 2000,
          timerProgressBar: true,
          toast: true,
          confirmButtonColor: "#0a4b85",
        });
      }
    }
  };




  const uploadImages = () =>{

    props.storeImagesInventory(autoRegistrationAdditionalData)
  
  }
  
  const uploadImagesWithAdd = () =>{

    props.storeImagesInventory(autoRegistrationData)
  
  }



  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 40,
      baseUnit: 4,
    },
  });

 

  const defaultAdditional = {
    page: 1
  };
  
  


  const onStoredata=(e)=>{
    e.preventDefault();
   
    
    if(vinValidation(autoRegistrationData.vin)){

   
   props.storeInventoryData(autoRegistrationData,value);
      

   }else
   {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "The vin is wrong, sorry we couldn't proceed with your reqeuest.",
      timer: 2000,
      timerProgressBar: true,
      toast: true,
      confirmButtonColor: "#0a4b85",
    });
    }
  
  
  
  }
   

  const handleClick = e => {
    const { id, checked } = e.target;
      setIsCheck([...isCheck, id]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item !== id));
      }
   
  };
 
  const autoTypeDefaultValue = [
    { value: "Auto", label: "Auto" }
  ];

  const autoCatagoryDefaultValue = [
    { value: "New", label: "New" }
  ];

  const titleStatus = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "BOS (exportable)", label: "BOS (exportable)" }

  ];


const menuPortal={
  menuPortal: base => ({ ...base, zIndex: 9999 })
}


const onChangeAsyncPaginate=()=>{

}

  const TooltipSearchFor = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Search by the selected value
    </Tooltip>
  );


  const deleteAuto=(id)=>{



Swal.fire({
  title: "Are you sure?",
  text: "You want to delete this vehicle",
  icon: "warning",
  width: 400,
  showCancelButton: true,
  timer: 4000,
  timerProgressBar: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, delete it!",
}).then((result) => {
  if (result.isConfirmed) {
    props.InventoryDelete(id);
    }
});

  }


  const latestNotificationCard=()=>{
    let latestNoficationData = props.latestNotification?.data;
  if(latestNoficationData){
if(latestNoficationData?.readStatus==null){

return(
  <MessageBox 
 bodyText={props.latestNotification?.data?.body} 
 postDateRelative={moment(props.latestNotification?.data?.created_at).add(5,'hours').fromNow()}
 postDate={moment(props.latestNotification?.data?.created_at).add(5,'hours').format('dddd DD-MM-YYYY')} 
  title={props.latestNotification?.data?.subject} 
  closeButton={<div className="blockquote-custom-close bg-light-custome shadow-sm" onClick={()=>{

  setCloseMessageBox(true)
  props.NotificationRead(props.latestNotification?.data?.hasNotificationIds)
  }} >Close</div>}
  onShow={closeMessageBox}
  gap={5}
   mediaQueryClass={'handleMedia'}
   width={'calc(100% - 174px)'}

   messageImage={"GA-01.png"}/>
)

  
}else{
  return(
    <></>
  )
}
    }


  }
    

  const loader = () => {
    return <></>;
  };

  const [toggleQrCode, setToggleQrCode] = useState([]);

  useEffect(() => {
    const initialState = props?.InventoryListResponse?.data?.data
      ? Array(props.InventoryListResponse.data.data.length).fill(false)
      : [];
    setToggleQrCode(initialState);
  }, [props.InventoryListResponse]);
  
  const handleToggle = (index) => {
    setToggleQrCode((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = !updatedState[index];
      return updatedState;
    });
  };
  


  const encryptId = (id) => CryptoAES.encrypt(id.toString(), 'autoListGhulam_123').toString();







  return (
    <>
      



      {guestCheck?<NavBar onSearchInputChange={onSearchInputChange} onSearch={onSearch}/>:''}


 

<div className="verticle-gap"></div>
  {/* ========functional Loader==========   */}
{searchText(searched,props.InventoryListResponse?.data?.data.length,props.searchInventory)}


{/*================================== auto list starts here=================================== */}
{ props.loading ? <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            /> :

  <div>


<div className="langDirection"  dir={JSON.parse(localStorage.getItem('lang_dir'))} >

<div className="container">
  
<div className="row">
  

{props.InventoryListResponse?.data?.data ? props?.InventoryListResponse?.data?.data.map((list,index)=>{

const encryptedId = encodeURIComponent(encryptId(list.real_auto_id));
const link = `/home/autoView/${encryptedId}`;


  return(
  <>
  
    <div className="col-md-3 mt-4" key={index}>
<div className="card auction-card">

 {
  props.InventoryListResponse?.images?.some(image => image.autos_id === list.real_auto_id) ? (
    props.InventoryListResponse?.images
      .filter(image => image.autos_id === list.real_auto_id)
      .map((img, ind) => (
        <div key={ind} >
         <span className="badge-status">{img.totalImages}</span>
         <div className="list-image-container" style={{cursor:'pointer'}} onClick={()=>{
 props.getImagesInventory(list.real_auto_id)
setTimeout(() => {
  setImagesModal(true)
},1000)


} }>
        <img
          alt="vehicle"
          className="card-img-top"
          src={`${process.env.REACT_APP_INVENTORY_IMAGES_ADRESS}/${img.image_name}`}
        />
        </div>
        </div>
      ))
  ) : (
    <img src={require('../../../assets/noimage2.png')}  alt="No image available"    width="100"/>
  )
}


      <div className="card-body auction-card-body">
        
        <h5 className="card-title">{list.make} {list.year} {list.model}</h5>
        <div>
        <span className="card-text-label">VIN: <span className="card-text"> {list.vin}</span></span>
        </div>

        <div>
        <span className="card-text-label">LOT: <span className="card-text"> {list.lot}</span></span>
        </div>

        <ul>
          <li>
            <strong>Price:</strong> <span className="auction-price">{list.auto_price}</span>
          </li>
          {/* <li>
            <strong>Time Remaining:</strong> 
          </li> */}
        </ul>
        { guestCheck?
      <Link to={`/vehicleDetails/${encodeURIComponent(CryptoAES.encrypt(list.real_auto_id.toString(),'autoListGhulam_123'))}`}  >
<button className="auction-button mt-3">View Details</button>
</Link>:

<Link to={`/home/inventoryView/${encodeURIComponent(CryptoAES.encrypt(list.real_auto_id.toString(),'autoListGhulam_123'))}`}  >
<button className="auction-button mt-3">View Details</button>
</Link>

          }
        {/* <button className="auction-button mt-3" >View Details</button> */}
      </div>

      <div className={`qr-code ${toggleQrCode[index] ? 'show' : ''}`}>
        <div  className="view-info">
          
        {/* <QRCode 
        value={'https://example.com'} // The data to encode in the QR code
        size={206} // Size of the QR code in pixels
        level={'M'}
      /> */}

<QrCodeGenerator
linkCreator={guestCheck?'/vehicleDetails':'/vehicleDetails'}
id={list.real_auto_id} 

/>

        </div>
       
      
      </div>


      <div className="icon-bar-inventory">
        { guestCheck?
      <Link to={`/vehicleDetails/${encodeURIComponent(CryptoAES.encrypt(list.real_auto_id.toString(),'autoListGhulam_123'))}`}  >
            <i className="bi bi-eye"></i>
          </Link>:

<Link to={`/home/inventoryView/${encodeURIComponent(CryptoAES.encrypt(list.real_auto_id.toString(),'autoListGhulam_123'))}`}  >
<i className="bi bi-eye"></i>
</Link>

          }

    {/* {  can(currentUserPermissions,'Show Qr Code')?
      <a href="#" onClick={(e) =>{
              e.preventDefault();
           handleToggle(index);
          }
           } title="show qr code" >
  <i className="bi bi-share"></i>
</a>:""
} */}

{ can(currentUserPermissions,'Edit Vehicle')? <a onClick={
          ()=>{
            props.getVehicleDetailsInventory(list.real_auto_id)

             setTimeout(()=>{
              props.showInventoryAdditionalModal()

            },550)
            setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,'onId':list.real_auto_id})

          }
        }>
        <i className="bi bi-pencil" ></i>                    
        </a>:""
      }

        { can(currentUserPermissions,'Delete Vehicle') ? <a onClick={()=>deleteAuto(list.real_auto_id)}>
            <i className="bi bi-trash"></i>
          </a>:''
       }

<WhatsAppButton
linkUrl=   { 
  `${process.env.REACT_APP_LINK}/vehicleDetails/${encodeURIComponent(CryptoAES.encrypt(list.real_auto_id.toString(),'autoListGhulam_123'))}`
      }
width={'20px'}
height={'20px'}
phoneNumber={process.env.REACT_APP_PHONE}
vehicleName={`${list.make} ${list.model} ${list.year}`}
vin={list.vin}
lot={list.lot}
price={list.auto_price}

/>

      </div>
    </div>
  </div>
  </>
  )
}):<NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 

}




{/* here you need to end  */}


<div style={{paddingBottom:'80px'}}></div>
  </div>
  </div>






      </div>

</div>
}


{ guestCheck?"":
    
    <div className="floating-search">
<form onSubmit={onSearch}>
    <div className="input-group">
<div className="form-outline">
  <input type="search"
   name="searchInventory"
    className="form-control"
     required
  onChange={onSearchInputChange}
  autoComplete="off"
   />
</div>
<button type="submit"  className="btn btn-primary">
  <i className="fas fa-search"></i>
</button>
</div>
</form>
    </div>
}




      <div className="beforeFixbar"></div>

      
     {can(currentUserPermissions,'Add Vehicle Inventory')? <button className="floating-btn" onClick={handleShow}>
       {t('key_add_vehicle')}
      </button>:''}

{ props.InventoryListResponse?.data?.data.length===0 ? '':

<div>
      {props.InventoryListResponse?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6 col-">
            <Pagination
              activePage={props.InventoryListResponse?.data.current_page}
              itemsCountPerPage={props.InventoryListResponse?.data.per_page}
              totalItemsCount={props.InventoryListResponse?.data.total}
              //   pageRangeDisplayed={5}
              onChange={
                searched==true? handleSearchPages
                :params.warehouse? handlePageChange :(pageNumber) => props.InventoryList(pageNumber)
                }
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}








{/* ========================================================
Modal of add Vehicle
======================================================== */}

      <Modal show={props.openInventoryModal} onHide={handleClose} size="xl">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add Vehicle </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={onStoredata}>
          {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )}

          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
            className="modal-body"
          >
  
            <div className="row">




            
              <div className="col-md-5 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="VIN#">
                  <Form.Control
                    type="text"
                    name="vin"
                    maxLength="17"
                    placeholder=' '
                    onChange={(e) => {
                      vinDecode(e.target.value);
                    }}
                    isInvalid={props.InventoryRegistrationErrors?.error?.vin?true:false}
                    required
                  />
                </FloatingLabel>
                {props.InventoryRegistrationErrors?.error?.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

              <div className="col-md-2 mb-3">
              <Select
                  options={autoTypeOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="AutoType"
                  name="autoType"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  setValue={autoRegistrationData.autoType}
                  defaultValue={autoTypeDefaultValue}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                {/* {props.InventoryRegistrationErrors?.error.autoType?<div className="validation-error">
                    Required !
                  </div>:''} */}
               
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="model"
                    type="input"
                    name="model"
                    placeholder=' '
                    onChange={(e)=>{onInputChange(e)}}

                    value={autoRegistrationData.model||''}
                    required
                  />
                  <label htmlFor="model">Model</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="make"
                    type="input"
                    name="make"
                    placeholder=' '
                    onChange={(e)=>{onInputChange(e)}}
                    value={autoRegistrationData.make||''}
                    required
                  />
                  <label htmlFor="make">Make</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="year"
                    type="input"
                    name="year"
                    placeholder=' '
                    onChange={(e)=>{onInputChange(e)}}
                    value={autoRegistrationData.year||''}
                    required
                  />
                  <label htmlFor="year">Year</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                   id="color" 
                   type="input" 
                  name="color" 
                  placeholder=' ' 
                  onChange={(e)=>{onInputChange(e)}}
                  required


                  />
                  <label htmlFor="color">Color</label>
                </Form.Floating>
              </div>
            </div>

            <div className="row">
              {/* <div className="col-md-3 mb-2">
                <label htmlFor="purchase_date" className="picker-inputs-lebel">
                  Purchase Date
                </label>

                <DatePicker
                  selected={autoRegistrationData.purchase_date}
                  onChange={(e) => handleDateInputChange(e,"purchase_date")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Purchase Date"
                  name="purchase_date"
                  required={true}
                  autoComplete="off"            
                  
                      />
              </div> */}


              <div className="col-md-6 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Auction
                </label>
                <Select
                  options={auctionOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Select Auction"
                  name="auction"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}
                  
                />

{props.InventoryRegistrationErrors?.error.auction?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              <div className="col-md-6 mb-2">
                <label htmlFor="city" className="picker-inputs-lebel">
                  City
                </label>
                <Select
                  options={cityOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="City"
                  name="city"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                {props.InventoryRegistrationErrors?.error.city?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              {/* <div className="col-md-2 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Buyer No#
                </label>
                <Select
                  options={buyerOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Buyer No"
                  name="buyer_no"
                  required={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                    {props.InventoryRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}
              </div> */}
            </div>

            <div className="row">
              {/* <div className="col-md-3 mb-2">
                <label
                  htmlFor="payment_to_auction"
                  className="picker-inputs-lebel"
                >
                  Payment to Auction
                </label>

                <DatePicker
                  selected={autoRegistrationData.payment_to_auction}
                  onChange={(e) => handleDateInputChange(e,"payment_to_auction")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  placeholderText="Payment"
                  name="payment_to_auction"
                  isClearable={true}
                  required={true}
                  autoComplete="off"

                />
              </div> */}

              <div className="col-md-6 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Port
                </label>
                <Select
                  options={portOptions}
                  theme={theme}
                  placeholder="Port"
                  name="port"
                  isClearable={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                
                {props.InventoryRegistrationErrors?.error.port?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>

              <div className="col-md-6 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                  Point of Loading
                </label>
                <Select
                  options={landingPointOptions}
                  theme={theme}
                  placeholder="Loading Point"
                  name="point_of_loading"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleChangeSelectors(e,selector)}

                />
                    {props.InventoryRegistrationErrors?.error.point_of_loading?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-2">
                <Form.Floating>
                  <Form.Control 
                   type="input"
                    name="lot"   
                    autoComplete="off"
                    required
                  placeholder=' '
                  onChange={(e)=>{onInputChange(e)}}
isInvalid={props.InventoryRegistrationErrors?.error.lot?true:false}
                   />
                  <label htmlFor="model">Lot</label>
                </Form.Floating>
                {props.InventoryRegistrationErrors?.error?.lot?<div className="validation-error">
                    Please, input a valid lot number, the lot number has already been taken !
                  </div>:''}
              </div>
              <div className="col-md-3 mt-2">
                <Form.Floating>
                  <Form.Control
                   id="model"
                   type="number" 
                  name="auto_price"   
                  required             
                   placeholder=' '
                   onChange={(e)=>{onInputChange(e)}}

 />
                  <label htmlFor="model">Auto Price</label>
                </Form.Floating>
              </div>
              <div className="col-md-5 mt-2">
                <div className="row pl-3">
                  <div className="col-md-6">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      To Dismantle
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='dismantle'
                      onChange={(e)=>{handleCheckbox(e)}}
                    />
                  </div>
                </div>

                <div className="row pl-3">
                  <div className="col-md-6">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      Self Delivered
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name='self_delivered'
                      onChange={(e)=>{handleCheckbox(e)}}

                 
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="notes"
                    className="form-label picker-inputs-lebel"
                  >
                    Customer Notes
                  </label>
                  <textarea
                    className="form-control"
                    id="notes"
                    name="customer_notes"
                    rows={4}
                    onChange={onInputChange}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                {/* <div style={{ marginTop: "47px" }}>
                  <FileUploader
               
                    handleChange={handleChange}
                    name="file"
                    types={["pdf"]}
                    label={"Upload invoice here"}
                    classes={""}
              
                    children={
                      <div
                        className={
                          dragAndDropSelected === false
                            ? "dragDrop"
                            : "dragDropSelected"
                        }
                      >
                    
                        <span>
                          {dragAndDropText}
                          {dragAndDropSelected === true ? (
                            <span
                              style={{ fontSize: "11px", color: "darkgreen" }}
                            >
                              {" "}
                              selected
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            fontSize: "10px",
                            top: "2px",
                            color: "rgb(10, 80, 134)",
                          }}
                        >
                          Pdf{" "}
                          {dragAndDropSelected === false ? (
                            ""
                          ) : (
                            <i className="fas fa-check"></i>
                          )}
                        </div>
                      </div>
                    }
                  />
                   &nbsp;  &nbsp;

{ autoRegistrationData.invoice!=null? <a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
  setAutoRegistrationData({...autoRegistrationData,invoice:null})
  setdragAndDropSelected(false)
  setdragAndDropText("Upload or Drag and Drop your files here")

  }    }>Reset </a>:'' }
                </div> */}

         

  






              </div>
            </div>
<div className="row">
  <div className="col-md-12">
  {  can(currentUserPermissions,'Add Vehicle Images')  ? <div style={{ marginTop: "10px" }}>
      
  <hr/>
       
      <FileUploader
   
        handleChange={handleImagesAddVehicle}
        name="file"
        types={["jpeg","jpg"]}
        label={"Upload invoice here"}
        classes={""}
        multiple
        children={
          <div
            className={
              dragAndDropSelectedImages === false
                ? "dragDrop"
                : "dragDropSelected"
            }
          >
        
            <span>
              {dragAndDropTextImages}
              {dragAndDropSelectedImages === true ? (
                <span
                  style={{ fontSize: "11px", color: "darkgreen" }}
                >
                  {" "}
                  selected
                </span>
              ) : (
                ""
              )}
            </span>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "2px",
                color: "rgb(10, 80, 134)",
              }}
            >
              jpeg, jpg{" "}
              {dragAndDropSelectedImages === false ? (
                ""
              ) : (
                <i class="fas fa-check"></i>
              )}
            </div>
          </div>
        }
      />
      &nbsp;  &nbsp;

      { autoRegistrationData.images.length>0? <><a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
        setAutoRegistrationData({...autoRegistrationData,images:[]})
        setdragAndDropSelectedImages(false)
        setdragAndDropTextImages("Upload or Drag and Drop your images here")
   
        }    }>Reset Images</a>  &nbsp;  
         {/* <a className="add-images-btn" onClick={()=>uploadImagesWithAdd()}>Add Images</a>   */}
          </> :'' }


    </div>:''

}
  </div>
  <div className="col">
  <div className="uploaded-images">
    {autoRegistrationData.images.map((image, index) => (
      <div key={index} className="image-container">
        <img
          src={URL.createObjectURL(image)}
          alt={`Uploaded ${index + 1}`}
          style={{ width: "100px", height: "100px", objectFit: "cover" }}
        />
      <p className="image-name">{image.name}</p>

      </div>
      
    ))}
  </div>

  </div>

</div>
            

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              ADD
            </button>
          </Modal.Footer>
        </form>
      </Modal>








{/*
 =================================================================================================================
 edit information by admin or normal users.
==================================================================================================================
*/}

<Modal show={props.openInventoryAdditionalModal} onHide={handleAdditionalClose} size="fullscreen" scrollable={true}>
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Vehicle Details of Inventory</Modal.Title>
          <CloseButton variant={"white"} onClick={handleAdditionalClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={onStoreAdditionalData}  encType="multipart/form-data" id="imageForm">
          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}

          <Modal.Body
            style={{
              maxHeight: "calc(110vh - 210px)",
              overflowY: "auto",
           
            }}
            className="modal-body"
          >

<div className="row">



              <div className="col-md-5 mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="VIN#">
                  <Form.Control
                    type="text"
                    name="vin"
                    maxLength="17"
                    placeholder=' '
                    value={autoRegistrationAdditionalData.vin||''}
                    onChange={(e) => {
                      vinDecodeAdditional(e.target.value);
                    }}
                    isInvalid={props.InventoryRegistrationErrors?.error.vin?true:false}
                    required
                  />
                </FloatingLabel>
                {props.InventoryRegistrationErrors?.error.vin?<div className="validation-error">
                    Please, input a valid vin, the vin has already been taken !
                  </div>:''}
              </div>

              <div className="col-md-2 mb-3">
              <Select
                  options={autoTypeOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="AutoType"
                  name="autoType"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  // setValue={autoRegistrationAdditionalData.autoType}
                  defaultValue={autoRegistrationAdditionalData?.autoType||''}
                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                {props.InventoryEditErrors?.error.autoType?<div className="validation-error">
                    Required !
                  </div>:''}
               
              </div>
            </div>



            <div className="row">
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="model"
                    type="input"
                    name="model"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeAdditional(e)}}

                    value={autoRegistrationAdditionalData.model||''}
                    required
                  />
                  <label htmlFor="model">Model</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="make"
                    type="input"
                    name="make"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeAdditional(e)}}
                    value={autoRegistrationAdditionalData.make||''}
                    required
                  />
                  <label htmlFor="make">Make</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="year"
                    type="input"
                    name="year"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeAdditional(e)}}
                    value={autoRegistrationAdditionalData.year||''}
                    required
                  />
                  <label htmlFor="year">Year</label>
                </Form.Floating>
              </div>
              <div className="col-md-3 mb-2">
                <Form.Floating>
                  <Form.Control
                   id="color" 
                   type="input" 
                  name="color" 
                  placeholder=' ' 
                  onChange={(e)=>{onInputChangeAdditional(e)}}
                  required
                  value={autoRegistrationAdditionalData.color||''}


                  />
                  <label htmlFor="color">Color</label>
                </Form.Floating>
              </div>
            </div>

            <div className="row">
              {/* <div className="col-md-3 mb-2">
                <label htmlFor="purchase_date" className="picker-inputs-lebel">
                  Purchase Date
                </label>

                <DatePicker
                  selected={autoRegistrationAdditionalData.purchase_date}
                  onChange={(e) => handleDateInputChangeAdditional(e,"purchase_date")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Purchase Date"
                  name="purchase_date"
                  required={true}
                  autoComplete="off"
                  />
              </div> */}


              <div className="col-md-6 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Auction
                </label>
                <Select
                  options={auctionOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Select Auction"
                  name="auction"
                  menuPortalTarget={document.body} 
                  defaultValue={autoRegistrationAdditionalData?.auction||''}
                  styles={menuPortal}
                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}
                  
                />

{props.InventoryEditErrors?.error.auction?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              <div className="col-md-6 mb-2">
                <label htmlFor="city" className="picker-inputs-lebel">
                  City
                </label>
                <Select
                  options={cityOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="City"
                  name="city"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  defaultValue={autoRegistrationAdditionalData?.city||''}

                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                {props.InventoryEditErrors?.error.city?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              {/* <div className="col-md-2 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Buyer No#
                </label>
                <Select
                  options={buyerOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Buyer No"
                  name="buyer_no"
                  required={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  defaultValue={autoRegistrationAdditionalData?.buyer_no||''}

                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                    {props.InventoryEditErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}
              </div> */}
            </div>

            <div className="row">
              {/* <div className="col-md-3 mb-2">
                <label
                  htmlFor="payment_to_auction"
                  className="picker-inputs-lebel"
                >
                  Payment to Auction
                </label>

                <DatePicker
                  selected={autoRegistrationAdditionalData.payment_to_auction}
                  onChange={(e) => handleDateInputChangeAdditional(e,"payment_to_auction")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  placeholderText="Payment"
                  name="payment_to_auction"
                  isClearable={true}
                  required={true}
                  autoComplete="off"


                />
              </div> */}

              <div className="col-md-6 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Port
                </label>
                <Select
                  options={portOptions}
                  theme={theme}
                  placeholder="Port"
                  name="port"
                  isClearable={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  defaultValue={autoRegistrationAdditionalData?.port||''}

                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                
                {props.InventoryEditErrors?.error.port?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>

              <div className="col-md-6 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                  Point of Loading
                </label>
                <Select
                  options={landingPointOptions}
                  theme={theme}
                  placeholder="Loading Point"
                  name="point_of_loading"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  defaultValue={autoRegistrationAdditionalData?.point_of_loading||''}

                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                    {props.InventoryEditErrors?.error.point_of_loading?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-2">
                <Form.Floating>
                  <Form.Control 
                   type="input"
                    name="lot"  
                    autoComplete="off" 
                    required
                  placeholder=' '
                  value={autoRegistrationAdditionalData.lot||''}
                  onChange={(e)=>{onInputChangeAdditional(e)}}
isInvalid={props.InventoryEditErrors?.error.lot?true:false}
                   />
                  <label htmlFor="model">Lot</label>
                </Form.Floating>
                {props.InventoryEditErrors?.error?.lot?<div className="validation-error">
                    Please, input a valid lot number, the lot number has already been taken !
                  </div>:''}
              </div>
              <div className="col-md-3 mt-2">
                <Form.Floating>
                  <Form.Control
                   id="model"
                   type="number" 
                  name="auto_price" 
                  required 
                  value={autoRegistrationAdditionalData.auto_price||''}
              
                   placeholder=' '
                   onChange={(e)=>{onInputChangeAdditional(e)}}

 />
                  <label htmlFor="model">Auto Price</label>
                </Form.Floating>
              </div>
              <div className="col-md-5 mt-2">
                <div className="row pl-3">
                  <div className="col-md-6">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      To Dismantle
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='dismantle'
                      onChange={(e)=>{handleCheckboxAddtional(e)}}

                      defaultChecked={props.getVehicleDetails?.data.to_dismantle=='true' ? true : false||'' }

                    />
                  </div>
                </div>

                <div className="row pl-3">
                  <div className="col-md-6">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      Self Delivered
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='self_delivered'
                      onChange={(e)=>{handleCheckboxAddtional(e)}}
                      defaultChecked={props.getVehicleDetails?.data.self_delivered=='true' ? true : false||'' }

                 
                    />
                  </div>
                </div>
              </div>
            </div>






            <div className="row ">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="notes"
                    className="form-label picker-inputs-lebel"
                  >
                    Customer Notes
                  </label>
                  <textarea
                    className="form-control"
                    id="notes"
                    name="customer_notes"
                    rows={4}
                    value={autoRegistrationAdditionalData?.customer_notes||''}

                    onChange={onInputChangeAdditional}
                  ></textarea>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div style={{ marginTop: "30px" }}>
                  <FileUploader
               
                    handleChange={handleChangeAdditional}
                    name="file"
                    types={["pdf"]}
                    label={"Upload invoice here"}
                    multiple
                    classes={""}
              
                    children={
                      <div
                        className={
                          dragAndDropAdditionalInvoiceSelected === false
                            ? "dragDrop"
                            : "dragDropSelected"
                        }
                      >
                    
                        <span>
                          {dragAndDropAdditionalInvoiceText}
                          {dragAndDropAdditionalInvoiceSelected  === true ? (
                            <span
                              style={{ fontSize: "11px", color: "darkgreen" }}
                            >
                              {" "}
                              selected
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            fontSize: "10px",
                            top: "2px",
                            color: "rgb(10, 80, 134)",
                          }}
                        >
                          Pdf{" "}
                          {dragAndDropAdditionalInvoiceSelected === false ? (
                            ""
                          ) : (
                            <i className="fas fa-check"></i>
                          )}
                        </div>
                      </div>
                    }
                  />
                   &nbsp;  &nbsp;

{ autoRegistrationAdditionalData.invoice!=null? <a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{


setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,invoice:[]})
setdragAndDropAdditionalInvoiceSelected(false)
                    setdragAndDropAdditionalInvoiceText("Upload or Drag and Drop your file here")


  }    }>Reset </a>:'' }
                </div>

                {props.InventoryEditErrors?.error.invoice?<div className="validation-error">
                Please, add a valid pdf file, the pdf file you are uploading has already in our system !
 !
                  </div>:''}


              </div> */}
            </div>






<div className="row">
<div className="col-md-6"></div>


{props?.getVehicleDetails?.invoice[0]?.file_name ? (
  <div className="col-md-4">
    <div className="form-label picker-inputs-lebel">
      Attached File
    </div>
    {props?.getVehicleDetails?.invoice.map((fileKey,fileIndex)=>{return(
      <>
      <div className="pt-2"></div>
 <a
 style={{ textDecoration: 'none', color: 'white' }}
 href={`${process.env.REACT_APP_INVENTORY_FILES_ADRESS}/${fileKey.file_name}`}
 target="_blank"
 rel="noopener noreferrer"
>
 <div className="card" style={{ background: 'rgba(22, 112, 207, 1)', color: 'white' }}>
   <div className="container">
     <div style={{ fontSize: '15px' }}>
       <div style={{ position: 'absolute', right: '12px' }}>
         <i className="fas fa-file-pdf"></i>
       </div>
       {fileKey.file_name}
     </div>
   </div>
 </div>
</a>
</>
    )})}
   
  </div>
) : null}






  
</div>




            <div className="pt-3"></div>


{/* <div className="header row">
<div className="col">Admins Section</div>


      
      </div> */}
<div className="pt-3"></div>







     {/* =================================================
         admin registration portion starts from here     
     =================================================      */}

            <div className="row">
             
              {/* <div className="col-md-5 col-sm-3 col-lg-4 mb-4">
                <Form.Floating>
                  <Form.Control
                    id="containerNoBooking"
                    type="input"
                    name="containerNoBooking"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeAdditional(e)}}

                    value={autoRegistrationAdditionalData.containerNoBooking||''}
               
                  />
                  <label htmlFor="containerNoBooking">Container No Booking</label>
                </Form.Floating>
              </div> */}
           
             

              {/* <div className="col-md-4 mb-3">
              <Select
                  options={autoCatagoryOptions}
                  theme={theme}
                  isClearable={true}
                  placeholder="Auto Placement"
                  name="auto_catagory"
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  // setValue={autoRegistrationData.autoType}
                  defaultValue={autoRegistrationAdditionalData?.auto_catagory||''}
                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}

                />
                {autoAdditionalDataError.auto_catagory_error?<div className="validation-error">
                    Auto placement is required !
                  </div>:''}
               
              </div> */}
            </div>
           

            {/* <div className="row">
              <div className="col-md-3  mb-2">
                <label htmlFor="purchase_date" className="picker-inputs-lebel">
                  Promised Picked up
                </label>

                <DatePicker
                  selected={autoRegistrationAdditionalData.pickUp}
                  onChange={(e) => handleDateInputChangeAdditional(e,"pickUp")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Select pick up"
                  name="pickUp"
                  // required={true}
                  autoComplete="off"
                />

{props.InventoryRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>


              <div className="col-md-3 mb-2">
              <label htmlFor="purchase_date" className="picker-inputs-lebel">
                  Delivered to Warehouse
                </label>

              <DatePicker
                  selected={autoRegistrationAdditionalData.toWarehouse}
                  onChange={(e) => handleDateInputChangeAdditional(e,"toWarehouse")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-Y"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Select to warehouse"
                  name="toWarehouse"
                  // required={true}
                  autoComplete="off"
                />
 {props.InventoryRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
              <div className="col-md-3 mb-2">
                <label htmlFor="city" className="picker-inputs-lebel">
                  Arrival Date
                </label>
                <DatePicker
                  selected={autoRegistrationAdditionalData.arrival_date}
                  onChange={(e) => handleDateInputChangeAdditional(e,"arrival_date")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Select arrival date"
                  name="arrival_date"
                  // required={true}
                  autoComplete="off"
                />

                 {props.InventoryRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>
              <div className="col-md-3 mb-2">
                <label htmlFor="auction" className="picker-inputs-lebel">
                  Unloading date
                </label>
                <DatePicker
                  selected={autoRegistrationAdditionalData.unloading_date}
                  onChange={(e) => handleDateInputChangeAdditional(e,"unloading_date")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Select unloading date"
                  name="unloading_date"
                  // required={true}
                  autoComplete="off"
                />
                    {props.InventoryRegistrationErrors?.error.buyer_no?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>
            </div> */}

            {/* <div className="row">

            <div className="col-md-3 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Title Status
                </label>
                <Select
                  options={titleStatus}
                  theme={theme}
                  placeholder="Title Status"
                  name="title_status"
                  isClearable={true}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
                  onChange={(e,selector) => handleMoreInfoSelectors(e,selector)}
                  
                  defaultValue={autoRegistrationAdditionalData?.title_status}

                />
                
                {props.InventoryRegistrationErrors?.error.port?<div className="validation-error">
                    Required !
                  </div>:''}

              </div>





              <div className="col-md-3 mb-2">
                <label
                  htmlFor="title_received"
                  className="picker-inputs-lebel"
                >
                  Title Received
                </label>

                <DatePicker
                  selected={autoRegistrationAdditionalData.title_received}
                  popperClassName="popper"
                  onChange={(e) => handleDateInputChangeAdditional(e,"title_received")}
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  placeholderText="Title Received"
                  name="title_received"
                  isClearable={true}
                  autoComplete="off"


                />
              </div>

              

              <div className="col-md-6 mt-4">
              <div className="row mt-3">
                  <div className="col-md-2">
                    <label
                      htmlFor="point_of_loading"
                      className="picker-inputs-lebel"
                    >
                      Keys
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name='keys'
                      defaultChecked={props.getVehicleDetails?.data.keys=='true' ? true : false||'' }
                      onChange={(e)=>{handleCheckboxAddtional(e)}}
                    />
                  </div>
                </div>
              </div>
            </div> */}

           

            <div className="row ">
              {/* <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="notes"
                    className="form-label picker-inputs-lebel"
                  >
                    Terminal Notes
                  </label>
                  <textarea
                    className="form-control"
                    id="notes"
                    name="terminal_notes"
                    rows={4}
                    style={{height:'40px'}}
                    value={autoRegistrationAdditionalData.terminal_notes||''}
                    onChange={onInputChangeAdditional}
                  ></textarea>
                </div>
              </div> */}
              
              <div className="col-md-6">
              <div className="pt-3">
              { props.uploadPercentage>0 && autoRegistrationAdditionalData.images.length>0 ? <ProgressBar animated now={100} label={`${props.uploadPercentage}%`}/>:''}
</div>
           {  can(currentUserPermissions,'Edit Vehicle Images')  ? <div style={{ marginTop: "10px" }}>
      
           
                  <FileUploader
               
                    handleChange={handleChangeImages}
                    name="file"
                    types={["jpeg","jpg"]}
                    label={"Upload invoice here"}
                    classes={""}
                    multiple
                    children={
                      <div
                        className={
                          dragAndDropSelectedImages === false
                            ? "dragDrop"
                            : "dragDropSelected"
                        }
                      >
                    
                        <span>
                          {dragAndDropTextImages}
                          {dragAndDropSelectedImages === true ? (
                            <span
                              style={{ fontSize: "11px", color: "darkgreen" }}
                            >
                              {" "}
                              selected
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            fontSize: "10px",
                            top: "2px",
                            color: "rgb(10, 80, 134)",
                          }}
                        >
                          jpeg, jpg{" "}
                          {dragAndDropSelectedImages === false ? (
                            ""
                          ) : (
                            <i class="fas fa-check"></i>
                          )}
                        </div>
                      </div>
                    }
                  />
                  &nbsp;  &nbsp;

                  { autoRegistrationAdditionalData.images.length>0? <><a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
                    setAutoRegistrationAdditionalData({...autoRegistrationAdditionalData,images:[]})
                    setdragAndDropSelectedImages(false)
                    setdragAndDropTextImages("Upload or Drag and Drop your images here")
               
                    }    }>Reset Images</a>  &nbsp;   <a className="add-images-btn" onClick={()=>uploadImages()}>Add Images</a>   </> :'' }
                </div>:''

           }

              </div>

           { can(currentUserPermissions,'Edit Vehicle Images')  ?  <ImagesGrid id={autoRegistrationAdditionalData.onId}/>:''}
          


            </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Add Details
            </button>
          </Modal.Footer>
        </form>
      </Modal>








{/* ========================================================
model for images
======================================================== */}

<Modal show={imagesModal} onHide={()=>{setImagesModal(false)}} size="fullscreen"  className="special_modal" centered >
        {/* <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">System Details</Modal.Title>
          <CloseButton variant={"white"} onClick={handleAdditionalClose}></CloseButton>
        </Modal.Header> */}

          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}
         <div style={{position:'absolute',right:'5%', top:'1%', zIndex:'99999', backgroundColor:'white'}} className="circle2"> <CloseButton  style={{fontSize:'12px', color:'black'}} onClick={()=>{setImagesModal(false)}}></CloseButton></div>

          <Modal.Body
            className="modal-body"
          >
            <div className="row">
             
              <div className="slider-container">

              <ImagesCarousel/>


              </div>


            </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

 
      </Modal>











    </>
  );
}

const mapStateToProps = (state) => {
  const {preDataLoadResponse } = state.preDataHandler;
  const {guestCheck} = state.authenticationHandler;

  const { SelectorsFetch } = state.SelectorsHandler;
  const {latestNotification,lastNotification,notificationsDeletedSuccess } = state.NotificationHandler;

  const {getVehicleDetails,searchInventory,loading,InventoryRegistrationSuccess,InventoryAdditionalRegistrationSuccess,InventoryImagesResponse, InventoryRegistrationErrors,openInventoryModal,cleanValues,InventoryListResponse,openInventoryEditModal,openInventoryAdditionalModal,currentPage,uploadPercentage,imagesDeletionSuccess,pointOfLoadingChangeSuccess,InventoryDeleted,selectedInventoryDeleted,selectedArray,openInventoryCommuncation,usersSelectorListResponse,cleanAdditionalValues,InventoryImagesUploadResponse,functionalLoader } = state.InventoryHandler;
// const {openInvoiceReg,openInvoiceInfo,noInvoicePresent} = state.InventoryInvoiceHandler;

  return {
    preDataLoadResponse:preDataLoadResponse,
    latestNotification:latestNotification,
    lastNotification:lastNotification,
    searchInventory:searchInventory,
    SelectorsFetch:SelectorsFetch,
    InventoryRegistrationErrors:InventoryRegistrationErrors,
    openInventoryModal:openInventoryModal,
    openInventoryEditModal:openInventoryEditModal,
    cleanValues:cleanValues,
    loading:loading,
    InventoryListResponse:InventoryListResponse,
    currentPage:currentPage,
    openInventoryAdditionalModal:openInventoryAdditionalModal,
    InventoryRegistrationSuccess:InventoryRegistrationSuccess,
    uploadPercentage:uploadPercentage,
    InventoryAdditionalRegistrationSuccess:InventoryAdditionalRegistrationSuccess,
    getVehicleDetails:getVehicleDetails,
    imagesDeletionSuccess:imagesDeletionSuccess,
    InventoryDeleted:InventoryDeleted,
    selectedInventoryDeleted:selectedInventoryDeleted,
    selectedArray:selectedArray,
  
    usersSelectorListResponse:usersSelectorListResponse,
    cleanAdditionalValues:cleanAdditionalValues,
    InventoryImagesUploadResponse:InventoryImagesUploadResponse,
    functionalLoader:functionalLoader,
    InventoryImagesResponse:InventoryImagesResponse,
    // notificationsDeletedSuccess:notificationsDeletedSuccess,
    guestCheck:guestCheck,
    getVehicleDetails:getVehicleDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    getSelectors: () => dispatch(getSelectors()),
    authCheck: () => dispatch(authCheck()),

    storeInventoryData:(InventoryData,customerID)=>dispatch(storeInventoryData(InventoryData,customerID)),
    showInventoryRegModal:()=>dispatch(showInventoryRegModal()),
    hideInventoryRegModal:()=>dispatch(hideInventoryRegModal()),
    showInventoryAdditionalModal:()=>dispatch(showInventoryAdditionalModal()),
    hideInventoryAdditionalModal:()=>dispatch(hideInventoryAdditionalModal()),
    InventoryList:(pageNumber)=>dispatch(InventoryList(pageNumber)),
    InventoryAdditionalRegistration:(InventoryAdditionalData,pageNumber,selector)=>dispatch(InventoryAdditionalRegistration(InventoryAdditionalData,pageNumber,selector)),
    resetProgressBar:()=>dispatch(resetProgressBar()),
    getImagesInventory:(id)=>dispatch(getImagesInventory(id)),
    changePointOfLoadingInventory:(id,loadingPoint,currentPage)=>dispatch(changePointOfLoadingInventory(id,loadingPoint,currentPage)),
    filterListOfWarhouses:(warehouse,placement,pageNumber)=>dispatch(filterListForWarhouses(warehouse,placement,pageNumber)),
    inputHandler: ({ key, value }) => dispatch(inputHandler({ key, value })),
    searchInventoryList:(querry,currentPage)=>dispatch(searchInventoryList(querry,currentPage)),
    InventoryDelete:(id)=>dispatch(InventoryDelete(id)),
    selectedInventoryDelete:(ids)=>dispatch(selectedInventoryDelete(ids)),
    usersSelectorListInventory:(query,page)=>dispatch(usersSelectorListInventory(query,page)),
    storeImagesInventory:(InventorysData)=>dispatch(storeImagesInventory(InventorysData)),
    getInventoryCommunication:(InventoryId)=>dispatch(getInventoryCommunication(InventoryId)),
    getLatestNotificationDetails:()=>dispatch(getLatestNotificationDetails()),
    NotificationRead:(id)=>dispatch(NotificationRead(id)),
    getVehicleDetailsInventory:(id)=>dispatch(getVehicleDetailsInventory(id)),

  };
};


export default connect(mapStateToProps,mapDispatchToProps)(VehicleList);
