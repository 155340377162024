import Protected from './ProtectedRoute';
import Login from '../components/pages/auth/Login';
import LoginInfo from '../components/pages/auth/LoginInfo';
import TrackVehicle from '../components/pages/auth/TrackVehicle';
import VehicleTracker from '../components/pages/settings/VehicleTracker';

import Loader from '../components/elements/PreDataLoader';
import Other from '../components/pages/Otherpage';
import AutosList from '../components/pages/autos/AutosList';
import NewPage from '../components/pages/NewPage';
import UsersList from '../components/pages/role_management/UsersList';
import Home from '../components/pages/Home';
import RoleManagement from '../components/pages/role_management/RoleManagement';
import Roles from '../components/pages/role_management/RolesList';
import ForbiddenAccess from '../components/pages/error/Forbidden';
import Carousel from '../components/pages/autos/AutosCarousel';
import AutoView from '../components/pages/autos/ViewAuto';
import CreateLoadingLists from '../components/pages/LoadingLists/CreateLoadingList';
import PendingLists from '../components/pages/LoadingLists/PendingList';
import ReadyLists from '../components/pages/LoadingLists/ReadyLists';
import RejectedLists from '../components/pages/LoadingLists/RejectedLists';
import LoadedLists from '../components/pages/LoadingLists/LoadedLists';
import Notification from '../components/pages/Notifications/Notification';

import LoadingListsTabs from '../components/pages/LoadingLists/LoadingListsTabs';
import Settings from '../components/pages/settings/Settings';
import Warehouses from '../components/pages/settings/Warehouses';
import Ports from '../components/pages/settings/Ports';
import Cities from '../components/pages/settings/Cities';
import Auctions from '../components/pages/settings/Auctions';
import AutoTypes from '../components/pages/settings/AutoType';

import BuyerNumbers from '../components/pages/settings/BuyerNumber';
import BillView from '../components/pages/autos/BillView';
// import AsyncSelector from '../components/pages/autos/AsyncSelector'
import ContainersList from '../components/pages/containers/ContainerList';
import Consignees from '../components/pages/settings/Consignees';
import Billers from '../components/pages/settings/Billers';
import MainBalances from '../components/pages/balances/MainBalances';
import Balances from '../components/pages/balances/Balances';
import VehicleList from '../components/pages/InventorySystem/Vehicles/VehicleList';
import VehicleView from '../components/pages/InventorySystem/Vehicles/VehicleView';

// import { useNavigate } from 'react-router-dom';
import React, { Fragment } from 'react';

import { 
    Routes,
    Route,
    Navigate,
  } from 'react-router-dom';
import { connect } from 'react-redux';

import {can,hasAnyRole} from '../components/utils/roleUtils';


  function AppRoutes(props){

// ================================================================
// permissions functions
// ================================================================

// const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;


const registerOptionalParamRoute = (optionalParams, element) => {
  if (optionalParams.length === 0)
      return <Fragment/>;

  const param = optionalParams[0];
  optionalParams.splice(0, 1);

  return <Route path={param} element={element}>
      {registerOptionalParamRoute(optionalParams, element)}
  </Route>;
};

const registerOptionalParams = (path, element) => {
  const params = path.split("/");
  let basePath = "";
  let optionalParams = [];

  for (let i = 0; i < params.length; i++) {
      if (params[i] === '')
          continue;
    
      if (!params[i].includes("?"))
      basePath += `${params[i]}/`;
      else
          optionalParams.push(params[i].substr(0, params[i].length - 1));
  }

  return <Route path={basePath} key={basePath} element={element}>
      {registerOptionalParamRoute(optionalParams, element)}
  </Route>;
};






return(
    <Routes>
                       {/*
                       ---------------------------------------------------------
                        for auth purposes  
                      ---------------------------------------------------------- 
                      */}
                    
              {/* <Route path={'/home/LoadingListsSection'} replace element={<Navigate to="loadingList"/>}/> */}

            {/* <Route path={"/home/RoleManagement"} replace element={<Navigate to="RoleManagement/users-list"/>}/>  */}
            <Route path="*" replace element={props.isAuthenticated? <Navigate to="/home/autos"/>:<Navigate to="/"/>}/> 
            <Route path={"/home"} replace element={props?.isAuthenticated? <Navigate to="/home/autos"/>:<Navigate to="/"/>}/> 



            {/* <Route path="/" index element={<Login/>} />  */}
            <Route path="/" element={<Login/>} > 
            <Route index element={<LoginInfo/>}/> 
            <Route path="TrackVehicle" element={<TrackVehicle/>}/> 

            </Route>



{/* //view for guest */}
<Route path="/vehicles/inventory" element={<VehicleList/>}/>
<Route path="/vehicleDetails/:id" element={<VehicleView/>}/> 



            <Route path="/carousel" index element={<Carousel/>} /> 


            <Route path={"/error"}  element={<ForbiddenAccess/>} /> 


            <Route path="/loader" element={<Loader width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"90px"}/> }/>

          {/* nested routes sidebar */}
            <Route element={<Protected  />}> 

            <Route path="/home" element={<Home/>} >
                
            <Route path="inventory" element={<VehicleList/>}/>
            <Route path="inventoryView/:id" element={<VehicleView/>}/> 


            {registerOptionalParams('autos/:warehouse?/:placement?', <AutosList/>)}
{/* 
                   <Route path="autos" element={<AutosList/>}/>  */}
                      <Route path="other" element={<Other/>}/> 

                      <Route path="new" element={<NewPage/>}/> 
                      <Route path="autoView/:id" element={<AutoView/>}/> 
            
                      <Route path="settings" element={can(currentUserPermissions,'Allow Settings')?<Settings/>:<ForbiddenAccess/>}/> 
                      <Route path="settings/ports" element={can(currentUserPermissions,'Allow Ports Settings')?<Ports/>:<ForbiddenAccess/>}/> 
                      <Route path="settings/cities" element={can(currentUserPermissions,'Allow Cities Settings')?<Cities/>:<ForbiddenAccess/>}/> 
                      <Route path="settings/auctions" element={can(currentUserPermissions,'Allow Auctions Settings')?<Auctions/>:<ForbiddenAccess/>}/> 
                      <Route path="settings/autoTypes" element={can(currentUserPermissions,'Allow AutoType Settings')?<AutoTypes/>:<ForbiddenAccess/>}/> 
                      <Route path="settings/buyerNumbers" element={can(currentUserPermissions,'Allow BuyerNumber Settings')?<BuyerNumbers/>:<ForbiddenAccess/>}/> 
                      <Route path="settings/warehouses" element={can(currentUserPermissions,'Allow Warehouses Settings')?<Warehouses/>:<ForbiddenAccess/>}/> 
                      <Route path="settings/consignee" element={can(currentUserPermissions,'Allow Consignees Settings')?<Consignees/>:<ForbiddenAccess/>}/> 
                      <Route path="settings/billers" element={can(currentUserPermissions,'Allow Billers Settings')?<Billers/>:<ForbiddenAccess/>}/> 
                      <Route path="settings/vehicleTracker" element={can(currentUserPermissions,'Public User Vehicles')?<VehicleTracker/>:<ForbiddenAccess/>}/> 

            

                      <Route path="BillView" element={<BillView/>}/> 
                      
                      <Route path="containerslist" element={<ContainersList/>}/> 
                      <Route path="notifications" element={can(currentUserPermissions,'Allow Notification')?<Notification/>:<ForbiddenAccess/>}/> 


     <Route path="MainBalancesTabs" element={<MainBalances/>}> 

<Route index element={<Balances/>}/> 
{/* <Route path="pendingMode/:mode" element={<PendingLists/>}/> 
<Route path="readyMode/:mode" element={<ReadyLists/>}/> 
<Route path="rejectMode/:mode" element={<RejectedLists/>}/> 
<Route path="loadedMode/:mode" element={<LoadedLists/>}/>  */}

{/* 
<Route path="roles" element={<Roles/>}/>  */}

</Route>



            {/* nested routes for users and roles management */}
     
            <Route path="RoleManagement" element={can(currentUserPermissions,'Manage Users')?<RoleManagement/>:<ForbiddenAccess/>}> 

                        <Route index element={<UsersList/>}/> 
                        <Route path="roles" element={<Roles/>}/> 

            </Route>



            {/* nested routes for Creating Loading Lists */}
     
            <Route path="LoadingListsSection" element={<LoadingListsTabs/>}> 

                        <Route index element={<CreateLoadingLists/>}/> 
                        <Route path="pendingMode/:mode" element={<PendingLists/>}/> 
                        <Route path="readyMode/:mode" element={<ReadyLists/>}/> 
                        <Route path="rejectMode/:mode" element={<RejectedLists/>}/> 
                        <Route path="loadedMode/:mode" element={<LoadedLists/>}/> 


                        <Route path="roles" element={<Roles/>}/> 

            </Route>






       
              {/* ends here */}

            </Route>
           </Route>




    </Routes>
    
    );





  }


  const mapStateToProps = (state) => {
    const { isAuthenticated } = state.authenticationHandler;
    const { preDataLoadResponse } = state.preDataHandler;

    return {
      preDataLoadResponse:preDataLoadResponse,
      isAuthenticated:isAuthenticated,
    };
  };
  

  export default connect(mapStateToProps)(AppRoutes);