import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";


function NavBarInventory(props) {
  return (
    <Navbar expand="lg" className="bg-body-tertiary bg-nav-body">
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >

<Nav.Link href={process.env.REACT_APP_ALL_RIGHTS_RESERVED_LINK} className='text-white screen-lg'>
  <img src={require('../../../assets/normLogo.png')} width="60px" />

</Nav.Link>



            <Nav.Link href={process.env.REACT_APP_ALL_RIGHTS_RESERVED_LINK} className='text-white'>Home</Nav.Link>
            {/* <Nav.Link href="#action2" className='text-white' as={Link} to="/">home</Nav.Link> */}
            <NavDropdown title={<span className="text-white">Sign In</span>} className='text-white' id="navbarScrollingDropdown">
              <NavDropdown.Item as={Link} to="/">Sign In</NavDropdown.Item>
              {/* <NavDropdown.Item href="#action4">
             Register
              </NavDropdown.Item> */}
              {/* <NavDropdown.Divider /> */}
              {/* <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item> */}
            </NavDropdown>
            {/* <Nav.Link href="#" disabled>
              Link
            </Nav.Link> */}
          </Nav>
          <Form className="d-flex" onSubmit={props.onSearch}>
            <Form.Control
              type="search"
              name='searchInventory'
              placeholder="Search VIN, LOT, Name"
              className="me-2"
              aria-label="Search"
              onChange={props.onSearchInputChange}  // Call the parent's function
              title="Search by vin, lot or name of the vehicle"
              required={true}
              autoComplete="off"
            />

            <Button variant="outline-success" type="submit">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
  );
}

export default NavBarInventory;