import React from 'react';
import { connect } from 'react-redux';
import { getImages,downloadImagesZipInventory } from "../../../../redux/actions";
import Loader from "../../../elements/Loader";
import ImageCustomCarousel from './../../../elements/ImageCustomCarousel'; // Import your custom carousel component

function VehiclesCarousel(props) {
  return (
    <>
      <div className="carousel-outer">
        {/* <button class="zipFileDownload" onClick={()=>downloadImagesZipInventory(props.imagesId)}>Download Images</button> */}
        {props.InventoryImagesResponse?.images ? (
          <ImageCustomCarousel
          zoomEffect={true}
          customThumnailColor={'#fcac32'}
            images={props.InventoryImagesResponse?.images.map((img) => `${process.env.REACT_APP_INVENTORY_IMAGES_ADRESS}/${img.image_name}`)} // Pass the image URLs as the `images` prop
          />
        ) : (
          <Loader
            width={"40px"}
            height={"40px"}
            iconColor={"white"}
            backgroundColor={"#deb726"}
            left={"50%"}
            top={"300px"}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { loading, InventoryImagesResponse } = state.InventoryHandler;
  return {
    loading,
    InventoryImagesResponse: InventoryImagesResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getImages: (id) => dispatch(getImages(id)),
    downloadImagesZipInventory:(id)=>dispatch(downloadImagesZipInventory(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesCarousel);
