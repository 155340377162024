import NodataFound from '../pages/error/noDataFoundMain';


export const searchText=(isSearched,data,searchText)=>{
    return(
        <>
        
        {
  isSearched && <div style={{textAlign:'center',color:'white'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{searchText}</span></p></div>
}
{data===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}
        
        </>
    )
}