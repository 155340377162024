import React, { useEffect, useState,useRef } from "react";
import "../../../../css/imagesGrid.css";
import {getVehicleDetailsInventory,imagesDeleteInventory,getImagesInventory} from "../../../../redux/actions";
import { connect } from "react-redux";
import Loader from "../../../elements/Loader";
import Checkbox from '../../../elements/Checkbox';

function VehicleImagesEditModal(props) {

  // const [checkedState, setCheckedState] = useState([]);
  const [checkedState, setCheckedState] = useState('checkmark');


  const [isCheckAll, setIsCheckAll] = useState(false);   
  const [isCheck, setIsCheck] = useState([]);



useEffect(()=>{

props.getImagesInventory(props.id);

setIsCheck([])

},[props.imagesDeletionSuccess,props.InventoryAdditionalRegistrationSuccess,props.InventoryImagesUploadResponse])






const onDeleteSelected=()=>{

  if(isCheck.length > 0){

   props.imagesDeleteInventory(isCheck,props.id)
   setIsCheck([])

  }else{
    alert('select any')
  }
}


const handleSelectAll = e => {
  setIsCheckAll(!isCheckAll);
  setIsCheck(props?.InventoryImagesResponse?.images.map(li => li.id));
  if (isCheckAll) {
    setIsCheck([]);
  }
};

const handleClick = e => {
  const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
 
};






  return (
    <>
     
{
  props?.InventoryImagesResponse?.images.length>0?
  <div>
  <div className="pt-3"></div>


      <div className="header row">
      <div className="col">Assigned Images to this Auto</div>
      <div className="col-2">Select All</div>

      <div className="col-1"> <span style={{float:'left'}}>  
        <Checkbox
        type="checkbox"
        name="selectAll"
        id="selectAll"
        handleClick={handleSelectAll}
        isChecked={isCheckAll}
      />           </span>
      
      </div>

      <div className="col"> 
{isCheck.length !=0?
      <a className="btn-delete" onClick={onDeleteSelected} >
      <i className="fa fa-trash"></i> &nbsp;
       Delete {isCheck.length} {isCheck.length==1?'image':'images'}
            </a>:''
}


            </div>
            




            </div>
      <div className="pt-3"></div>

</div>:''
    
}
      <div
        className="container"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
        }}
      >
        <div className="row">



       {props.loading? 
       <div style={{position:'relative', top:'0'}}>
        <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"90%"}
            />
       </div>
        :
        props.InventoryImagesResponse?.images?.map((img,index)=>{

return (

  
  <div className="col-xl-2 col-lg-3 col-md-3" key={index}>

            <div className="img-wrap">
              <div className="newCheckbox">
                <label class="contain">
                  <input type="checkbox" 
                    id={img.id}
                          // value={img.id}
                          name={img.id}

    //  onChange={(e) =>  handleOnChangeCheckbox(e,index)}
        // onClick={(e) =>  handleOnChangeCheckbox(e,index)}
        // checked={checkValues[index]||''}
        // onChange={() => handleOnChange(index)}
        onChange={handleClick}

        checked={isCheck.find(li=>li===img.id)}

                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              {/* <span className="close">
                {" "}
                <i className="fa fa-trash"></i>
              </span> */}
              <span className="shade"></span>
              <img src={`${process.env.REACT_APP_INVENTORY_IMAGES_ADRESS}/${img.image_name}`} 

style={{ width: "180px", height: "180px" }}
              />

            </div>
          </div>
        
)

       })


    }


          
        </div>
      </div>
    </>
  );
}


const mapStateToProps = (state) => {

  const {loading, getVehicleDetails,imagesDeletionSuccess,InventoryAdditionalRegistrationSuccess,InventoryImagesResponse,InventoryImagesUploadResponse } = state.InventoryHandler;


  return {
  
    loading:loading,
   
    getVehicleDetails:getVehicleDetails,
    imagesDeletionSuccess:imagesDeletionSuccess,
    InventoryAdditionalRegistrationSuccess:InventoryAdditionalRegistrationSuccess,
    InventoryImagesResponse:InventoryImagesResponse,
    InventoryImagesUploadResponse:InventoryImagesUploadResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    imagesDeleteInventory:(id,autoId)=>dispatch(imagesDeleteInventory(id,autoId)),
    getVehicleDetailsInventory:(id)=>dispatch(getVehicleDetailsInventory(id)),
    getImagesInventory:(id)=>dispatch(getImagesInventory(id)),
  };
};




export default connect(mapStateToProps,mapDispatchToProps)(VehicleImagesEditModal);
